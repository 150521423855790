import { Injectable, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AccountInfo, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MicrosoftLoginService implements OnDestroy {

  private readonly destroying$ = new Subject<void>();

  constructor(
    private msalService: MsalService,
    private authService: AuthService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private msalBroadcastService: MsalBroadcastService,
  ) {}

  startMicrosoftLoginFlow(): void {
    const request = {
      scopes: ['user.read'],
      prompt: 'select_account',
    };
    this.msalService.loginRedirect(request);
  }

  listenToMicrosoftLoginEvents(): void {
    this.msalBroadcastService.inProgress$.pipe(takeUntil(this.destroying$)).subscribe((status: InteractionStatus) => {
      // We don't want to act for success logins etc. if we are in mobile login flow as it would break the flow by logging user in etc.
      if (status === InteractionStatus.None && !this.mobileLoginInProgress()) {
        this.checkAndSetActiveAccount();
      }
    });
  }

  checkAndSetActiveAccount(): Promise<AccountInfo> {
    return new Promise((resolve, reject) => {
      let activeAccount = this.msalService.instance.getActiveAccount();
      if (!activeAccount) {
        const allAccounts = this.msalService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
          activeAccount = allAccounts[0];
          this.msalService.instance.setActiveAccount(activeAccount);
        } else {
          reject(new Error('No accounts available to set as active.'));
          return;
        }
      }
      resolve(activeAccount);
    });
  }

  acquireTokenRedirect(): void {
    this.msalService.acquireTokenRedirect({
      scopes: ['user.read'],
    });
  }

  acquireTokenSilent(account: AccountInfo): Promise<AuthenticationResult> {
    if (!account) {
      return Promise.reject('No account provided.');
    }
    return this.msalService.acquireTokenSilent({account, scopes: ['user.read']}).toPromise();
  }

  mobileLoginInProgress(): boolean {
    const mobileLoginParam = this.route.snapshot.queryParams.mobilelogin;
    const mobileLoginFlag = localStorage.getItem('impact-sso-mobile-login');
    return mobileLoginFlag || mobileLoginParam;
  }

  async handleLoginFlow(justReturnToken = false): Promise<string | void> {
    try {
      const account = await this.checkAndSetActiveAccount();
      const authenticationResult = await this.acquireTokenSilent(account);
      const loginResponse = await this.loginWithAdToken(account.username, authenticationResult.accessToken);

      if (justReturnToken) {
        return loginResponse.token;
      }

      this.handleLoginResponse(loginResponse);
    } catch (error) {
      console.error('Error in login flow:', error);
      this.acquireTokenRedirect();
    }
  }

  loginWithAdToken(username: string, adToken: string): Promise<any> {
    return this.authService.login({ username, ad_token: adToken }).toPromise();
  }

  handleLoginResponse(response): void {
    this.authService.handleLoginResponse(response).then(() => {
      // Login successful. Redirect handled in promise in authService.
    }, (reason) => {
      this.toastr.warning(this.translateService.instant(reason));
    });
  }

  ngOnDestroy() {
    this.destroying$.next();
    this.destroying$.complete();
  }

}
